.product-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .product-details-group {
        width: 100%;
    }

    .product-details__row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        width: 100%;

        .product-details__name {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .product-details__name-main {
                font-size: 2rem;
                font-weight: bold;
                color: $color-primary;
            }
            .product-details__name-second {
                font-size: 1.6rem;
                font-weight: bold;
                color: $color-grey-1;
            }
        }

        .product-details__property-group {
            font-size: 1.6rem;
            margin-top: 1.8rem;
            font-weight: bold;
            color: $color-primary;
            text-transform: uppercase;
        }

        .product-details__property {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            width: 100%;

            .product-details__property-name {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 1.3rem;
                font-weight: bold;
                color: $color-grey-2;
                width: 20%;
            }

            .product-details__property-value {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                font-size: 1.3rem;
                width: 70%;
            }

            .product-details__property-value-main {
                width: 100%;
                color: $color-primary;
                font-weight: bold;
            }

            .product-details__property-value-secondary {
                width: 100%;
                color: $color-grey-1;
                font-weight: bold;
            }
        }
    }
}

.product-details__photos {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .product-details__photo {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border: 1px solid $color-grey-3;
        border-radius: 0.3rem;
        overflow: hidden;
        margin: 0.5rem 1rem 0.5rem 0;

        &.is-main {
            border-color: $color-primary;
        }

        img {
            position: relative;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.product-edit-photo__upload-photo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;

    .product-edit-photo__input-wrapper {
        flex: 1 0 50%;
        position: relative;
    }

    .product-edit-photo__input-placeholder {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid $color-grey-1;
        border-right: none;
        border-radius: 0.3rem 0 0 0.3rem;
        padding: 0.2rem 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.2rem;
        cursor: pointer;

        .filename {
            flex: 1 0 50%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .material-icons {
            font-size: 1.8rem;
            margin-right: 1rem;
            color: $color-grey-2;
            flex: 0 0 2rem;
        }
    }

    .product-edit-photo__input {
        width: 100%;
        height: 100%;
        position: relative;
        opacity: 0;
    }

    .product-edit-photo__button {
        flex: 0 0 100px;
        height: 3rem;
        color: $color-grey-2;
        background-color: transparent;
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: bold;
        border: 1px solid $color-grey-1;
        border-radius: 0 0.3rem 0.3rem 0;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: $color-primary-c;
            background-color: $color-primary;
            border-color: $color-primary;
        }
    }
}

.product-edit-photo__content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.product-edit-photo__photo-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $color-grey-3;
    padding: 1rem;
    margin: 2rem 0 0;
    border-radius: 0.3rem;
    position: relative;

    .product-edit-photo__photo-image {
        width: 200px;
        max-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 200px;
            max-height: 200px;
        }
    }

    .product-edit-photo__photo-is-main {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-primary;
    }

    .product-edit-photo__photo-buttons {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-direction: column;

        ._button-light {
            margin-bottom: 1rem;
        }
    }
}

.product-edit-bindings {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;

    .product-edit-bindings__group-title {
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-primary;
        margin: 3rem 0 1rem;
    }

    .product-edit-bindings__product {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid $color-grey-3;
        padding: 0.5rem 1rem;
        margin: 0 0 1rem;
        border-radius: 0.3rem;
        font-size: 1.2rem;

        color: $color-grey-2;
    }

    .product-edit-bindings__product-ean {
        flex: 0 0 30%;
        font-weight: bold;
    }

    .product-edit-bindings__product-name {
        flex: 1 0 30%;
        color: black;
    }

    .product-edit-bindings__product-delete {
        flex: 0 0 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .material-icons {
            font-size: 1.6rem;
            color: $color-grey-2;
        }
    }
}
