@import '../../scss/variables';
.select-field__wrapper {
    width: 100%;
    position: relative;
    height: 3rem;

    &::before {
        content: 'expand_more';
        font-family: 'Material Icons';
        font-size: 1.6rem;
        position: absolute;
        width: 2rem;
        height: 100%;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-grey-2;
        z-index: 1;
    }
}

.select-field {
    width: 100%;
    height: 3rem;
    padding: 0 1rem;
    border: 1px solid $color-grey-1;
    background-color: transparent;
    border-radius: 0.3rem;
    -webkit-appearance: none;
    z-index: 2;
    position: relative;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    color: $color-primary;
}