.category-tree {
    width: 100%;
}

.category-tree__wrapper {
    width: 100%;
    border: 1px solid $color-grey-3;
    border-bottom: none;

    &:last-child {
        border-bottom: 1px solid $color-grey-3;
    }
}
.category-tree__main-category {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .category-tree__main-category__switch {
        @include ff-icon();
        font-size: 2rem;
        width: 4rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 4rem;
        cursor: pointer;
    }

    .category-tree__main-category__label {
        font-size: 1.4rem;
        flex: 1 0 10rem;
        padding: 0 0 0 1rem;
        cursor: pointer;
        height: 5rem;
        display: flex;
        align-items: center;
    }

    .category-tree__main-category__count {
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-grey-2;
        margin: 0 1rem 0 0;
        flex: 0 0 auto;
    }

    .category-tree__main-category__buttons {
        display: flex;
        padding-right: 1rem;

        ._button-light {
            margin: 0 0.5rem 0 0;
        }
    }
}

.category-tree__child-category {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid $color-grey-3;
    padding: 1rem 0;
    background-color: $color-background;

    .category-tree__child-category__label {
        flex: 1 0 10rem;
        padding: 0 0 0 4rem;
        font-size: 1.3rem;
    }

    .category-tree__child-category__count {
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-grey-2;
        margin: 0 1rem 0 0;
        flex: 0 0 auto;
    }

    .category-tree__child-category__buttons {
        display: flex;
        align-items: center;
        padding-right: 1rem;

        ._button-light {
            margin: 0 0.5rem 0 0;
        }
    }
}
